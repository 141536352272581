.tablerow {
    padding: 0px !important;
}

.table2row {

    padding: 0px !important;
}

.borderright {
    border-right: 0.5px solid #707070 !important;
}

.MuiTableRow-root {
    .headerstyl {
        background-color: #DDE4FE;
    }

}

.tabletitleheader {
    text-align: left;
    align-items: center;
    justify-content: left;
    display: flex;
    height: 50px;
    background-color: #0B4387;
    padding-left: 10px;
}

.addnewrowdiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0.5rem;
}

.addrowicon {
    width: 16px;
    height: 16px;
}

.addbutton {
    text-decoration-line: underline !important;
    text-transform: capitalize !important;
    display: flex;
    color: white !important
}

.tabletitle {
    text-align: left;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 50px;
    border-radius: 10px 0px 0px 0px;
    background-color: #0B4387;
    width: 18%;
    margin-left: 0px;

    @media only screen and (max-width:767px) {
        width: 30%;
    }


}

.modifyiconcontainer {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
}

.tabledropicon {
    width: 17px;
    height: 17px;
    /* visibility: hidden; */
}

.tablerowtick {
    width: 20px;
    height: 20px;
}

.tablecross {
    width: 15px;
    height: 17px;
}

.tooltipstyl {
    background-color: white;
}

/* .tx_r_n {
    border-left: 1px solid red;
}
.tx_p_n {
    border-right: 1px solid red;
} */

.tx_r_y {
    border-left: 1px solid red;
}

.tx_p_y {
    border-right: 1px solid red;
}

.disabledCellOnSplit {
    background-color: #c9c6c6;
    opacity: 0.4;
}