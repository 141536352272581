table{
    /* border-width: 1px;
    border-style: solid;
    border-color: #FF0000; */
}

tr {
    /* border-width: 1px;
    border-style: solid;
    border-color: #00FF00; */
}

td {
    padding:0.25em !important;
}

.inputField{
    padding:0.25em;
}
.StopsContainer{
    display: flex;
    flex-direction: column;
    text-align: right;
    padding: 1em;
    /* border:1px solid red; */
}

