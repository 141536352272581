/**
* Added By Akash
**/
.BottomRow {
    flex: 1 1 auto;
}

.Panel {
    display: flex;
    flex-direction: column;
    /* padding: 10px; */
}

.PanelContent {
    height: calc(100vh - 90px);
    width: 100%;
    /* background-color: aquamarine; */
    display: flex;
    flex-direction: row;
    overflow: hidden;
    /* padding: 10px; */
    /* align-items: center; */
    /* justify-content: center;
    overflow: hidden;
    border-radius: 0.5rem; */
}

.MuiButtonBase-root-tabstyle {
    font-size: 11px !important;
    padding: 10px 1px !important;
}

.MuiDataGrid-columnHeaders {
    background-color: rgba(241, 246, 252, 1);
    color: rgba(0, 0, 0, 1);
    font-size: 11px;
}

.super-app-theme--cell {
    background-color: white;
    font-size: 11px;
}

/*
* END
*/

.maincontainer_dispatch {
    background-color: white;
    display: flex;
    flex: 2;
    padding: 1.5rem;
}

.supcontainer {
    display: flex;
    flex: 1;
    padding: 10px;
    height: 100%;
}

.supcontainer-right {
    display: flex;
    flex: 1;
    padding: 10px;
    height: 100%;
    flex-direction: column;
}

.loadconteiners {
    display: flex;
    height: 50%;
    padding-bottom: 10px;

}

.dropconteiners {
    display: flex;
    height: 50%;
    padding-top: 10px;
}

.MuiTableCell-root-text {
    color: white !important;
    padding: 0px 15px !important;
    height: 40px !important;
}

.MuiButtonBase-root-tabstyle {
    font-size: 11px !important;
    padding: 10px 3px !important;
}

.subitemcolumnstyle {
    display: flex;
    flex-direction: row;
    height: 25px;
    font-size: 12px;
    font-weight: bold;

}

.MuiTableCell-root-subitemrowstyle {
    font-size: 13px !important;
}

.MuiTableCell-root-style {
    padding: 5px !important;
}

.rightsubtable {
    padding: 15px;
}

.MuiTypography-root {
    color: black;
}

.tableheader-style {
    height: 40px !important;
    padding: 0px !important;
}

.assignedTenderClass {
    background-color: blanchedalmond !important;
}

.tenderedClass {
    background-color: #fff !important;
}

.disableDriverRow {
    background-color: rgb(227 227 227) !important;
}

.defaultLighBlue {
    background-color: rgba(241, 246, 252, 1) !important
}

.highLightWithRed {
    color: red;
}

.highLightWithYellow{
    background: yellow;
}
/* Main Layout
.BottomRow {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* Controls */
.TopControls {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    flex-wrap: wrap;
}

/* Desktop Layout - Side by Side */
.PanelGroupContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: calc(100vh - 50px); /* Adjust based on header */
}

/* Panels */
.Panel {
    flex: 1;
    overflow: auto;
    min-height: 300px; /* Prevent height collapse */
    border: 1px solid #ccc;
    padding: 10px;
}

/* Responsive - Mobile View */
@media (max-width: 768px) {
    .PanelGroupContainer {
        flex-direction: column;
        height: auto;
    }

    .PanelGroup {
        flex-direction: column !important; /* Override horizontal layout */
    }

    .Panel {
        width: 100%;
        min-height: 250px;
    }
} 
.BottomRow {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.Panel {
    flex: 1;
    min-height: 150px; /* Prevents collapsing */
    max-height: 80vh; /* Prevents over-expanding */
    overflow: auto; /* Enables scrolling inside tables */
}

.PanelContent {
    width: 100%;
    height: 100%;
    overflow-y: auto; /* Ensures vertical scroll */
}
