/* .css-yrdy0g-MuiDataGrid-columnHeaderRow{
    background-color: #DDE4FE;
} */

.paperstyl{
    display: flex !important;
    flex-direction: column; 
    flex: 1; 
    padding: 1rem !important;
    align-items: right !important; 
    max-height: 60% !important;
}
.gridby_container{
    width: 100%; 
    background-color: #0B4387; 
    height: 55px; 
    border-top-left-radius: 10px; 
    border-top-right-radius: 10px; 
    align-items: center; 
    display: flex; 
    justify-content: end ;

}