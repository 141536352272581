.css-11yukd5-MuiTabs-indicator {
    background-color: #ffffff;
    display: none;
}

/* .css-1a4cg4j-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #ffffff !important;
    font-weight: 900;
}
.MuiButtonBase-root.MuiTab-root {
    color: #ffffff ;
    outline: none;
} */
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    color: #0911A7 !important;
}

.menu-link:hover {
    background-color: lightgray;
    border-radius: 5px;
}