table {
    /* border-width: 1px;
    border-style: solid;
    border-color: #FF0000; */
}

tr {
    /* border-width: 1px;
    border-style: solid;
    border-color: #00FF00; */
}

td {
    padding: 0.25em !important;
}

.MuiInputBase-input {
    font-size: 14px !important;
    /* @media screen and (max-width: 992px) {
        font-size: 12px !important;
    } */
}

.inputField {
    padding: 2em;
}

.mainContainer {
    display: inline-block;
    text-align: left;
    padding: 1em;
    font-size: 1em !important;
}

.width_100 {
    width: 100%;
}

.width_50 {
    width: 50%;
}

.width_49 {
    width: 49%;
}
.width_99{
    padding-bottom:0.5rem;
    width:99%
}
.paddingleft{
    padding-left: 0.2rem;
}
.paddingright{
    padding-right: 0.2rem;
}
.paddingbottom{
    padding-bottom:0.5rem;
}

.width_33 {
    width: 33%;
    padding-left: 0.5rem ;
}

.dividersty {
    margin-top: 2%;
    margin-bottom: 1%;
    width: 100%;
}

.paperstyl {
    display: inline-block;
    padding: 1rem;
    margin-top: 1em;
    width: 100%;
}

.paperstyl1 {
    padding: 1rem;
    margin-top: 1em;
    width: 100%;
}

.customernotes {
    display: flex;
    width: 100%;
    flex-direction: column;
}
.stoppaper2{
    width: 33.3%;
    flex-direction: row; 
    display: flex; 
    padding-bottom: 0.5rem;
}
.pickinguprow{
    flex-direction:row;
    display:flex;
}
.drivernotes{
    display: flex;
    width: 100%; 
    flex-direction: column;
}
.stopcontainerdiv{
    display: flex;
    flex-direction: row;
    justify-content: space-around; 
}
.stoppaper3{
    flex: 1; 
    margin: 5; 
    display: flex; 
    justify-content: center; 
    padding-top: 10;
    padding: 0.5rem
}
.commodity_truck{
    align-items:end;
    display:flex;
}
.autocomplestyl .MuiInputBase-root{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

/* .datepicker .MuiInputBase-input{
    padding: 9px 14px !important;
    
}
.datepicker .MuiInputBase-root .MuiOutlinedInput-notchedOutline{
    border-color: rgb(189, 243, 247) !important;
}
.datepicker .MuiFormLabel-root {
    color: rgb(168, 160, 160) !important;
}
.datepicker .MuiSvgIcon-root{
    width: 17px;
    height: 17px;

} */