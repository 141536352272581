.css-11yukd5-MuiTabs-indicator {
    background-color: #ffffff;
    display: none;
}

/* .css-1a4cg4j-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #ffffff !important;
    font-weight: 900;
}
.MuiButtonBase-root.MuiTab-root {
    color: #ffffff ;
    outline: none;
} */
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    color: #0911A7 !important;
}

.menu-link:hover {
    background-color: lightgray;
    border-radius: 5px;
}
.driver-form-container{
    margin-top:3%;
    display:grid;
    grid-template-columns:1fr 1fr 1fr 1fr
}

.driver-input-container{
    display: grid;
    grid-template-columns: 35% 40%;
    grid-column-gap: 2%;
    -webkit-column-gap: 2%;
            column-gap: 2%;
    align-items: center
}

.truck-info-input{
    display: grid; 
    grid-template-columns: 20% 1fr; 
    grid-column-gap: 3%; 
    -webkit-column-gap: 3%; 
            column-gap: 3%; 
    align-items: center;
    padding-bottom:1%;
}

.truck-info-multi-input{
    display: grid; 
    grid-template-columns: 20% 10% 1fr; 
    grid-column-gap: 3%; 
    -webkit-column-gap: 3%; 
            column-gap: 3%; 
    align-items: center;
    padding-bottom:1%;
}

.truck-info-multi-input3{
    display: grid; 
    grid-template-columns: 20% 15% 4% 1fr; 
    grid-column-gap: 3%; 
    -webkit-column-gap: 3%; 
            column-gap: 3%; 
    align-items: center;
    padding-bottom:1%;
}

.truck-driverinfo-list-wrapper{
    overflow-x: hidden;
    margin:2% 
}

.truck-driverinfo-empty-data-wrapper{
    margin:  0 auto;
    display: grid
}

.company-info-heading {
    text-align: center;
    background: blue;
    padding-top: 10px;
    padding-bottom: 10px;
}

.truck-driver-subheading {
font-weight: bold;
margin-bottom: 2%;
}
.text-field-size{
    width: 10%
}

.truckinfo-date-field-size{
   width: 20%;
}

.truckinfo-phone-field-size{
    width:35%;
   }

.driverinfo-text2{
   width:50%;     
}
.driver-number-field{
 width: 30% 
}

.overall-availability-text{
    padding: 2%;
}

.truck-driver-back-button{
  
   cursor: pointer;
    background-color: red;
    padding: 10px;
    border-radius: 12px;
    float: right;
}

.driver-name-text{
    width: 110%;
}
.driver-name-middlename{
    width: 140%;
}
.driver-name-lastname{
    width: 39%;
}
.truck-liscense-no{
   width: 125%;
}

.truck-info-text-description{
  width: 50%;
}
.truck-width-normal{
    width: 50%;
    
}

.truck-info-safetytruck{
     width: 40%;
}

.vendor-code{
   width: 20%;
}
.vendor-text-field{
    width: 50%;
}

.overall-avail-status{
    padding: 2%;
    margin-top:2% 
}


@media only screen and (max-width:1023px){

    .driver-form-container{
        margin-top:3%;
        display:grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 10%;
        row-gap: 10%;
    }


    .driver-input-container{
        display: grid;
        grid-template-columns: 42% 40%;
        grid-column-gap: 5%;
        -webkit-column-gap: 5%;
                column-gap: 5%;
        align-items: center;
        
    }
    .truck-lable{
        justify-self: end;
    }
    .text-field-size{
        width: 13%
    }
    .truckinfo-date-field-size{
        width: 30%;
     }
     .truckinfo-phone-field-size{
        width:45%;
       }

       .truck-info-input{
        display: grid; 
        grid-template-columns: 23% 1fr; 
        grid-column-gap: 3%; 
        -webkit-column-gap: 3%; 
                column-gap: 3%; 
        align-items: center;
        padding-bottom:1%;
    }
    .truck-info-multi-input3{
        display: grid; 
        grid-template-columns: 17% 17% 9% 49%;
        grid-column-gap: 9%;
        -webkit-column-gap: 9%;
                column-gap: 9%; 
        align-items: center;
        padding-bottom:1%;
    }

    .truck-info-multi-input{
        display: grid; 
        grid-template-columns: 23% 16% 1fr; 
        grid-column-gap: 3%; 
        -webkit-column-gap: 3%; 
                column-gap: 3%; 
        align-items: center;
        padding-bottom:1%;
    }
    .driverinfo-text2{
        width:80%;
    }

    .driver-name-text{
        width: 110%;
    }
    .driver-name-middlename{
        width: 150%;
    }
    .driver-name-lastname{
        width: 39%;
    }
    .truck-liscense-no{
        width: 105%;
     }

}

@media only screen and (max-width:767px){
    
    .driver-form-container{
        margin-top:3%;
        display:grid;
        grid-template-columns: 1fr;
        grid-row-gap: 8%;
        row-gap: 8%;
    }

    .driver-input-container{
        display: grid;
        grid-template-columns: 35% 35%;
        grid-column-gap: 5%;
        -webkit-column-gap: 5%;
                column-gap: 5%;
        align-items: center
    }

    .overall-availability-text{
        padding: 5%;
    }
    .text-field-size{
        width: 25%
    }
    .truckinfo-date-field-size{
        width: 50%;
     }
     .truck-driverinfo-list-wrapper{
        overflow-x: hidden;
        padding: -20px;
    }
    .truck-driver-back-button{
        margin-right: 10%;
    }
    .truck-info-multi-input{
        display: grid; 
        grid-template-columns: 20% 28% 1fr; 
        grid-column-gap: 6%; 
        -webkit-column-gap: 6%; 
                column-gap: 6%; 
        align-items: center;
        padding-bottom:1%;
    }

    .truck-info-multi-input3{
        display: grid; 
        grid-template-columns: 21% 20% 7% 73%;
        grid-column-gap: 5%;
        -webkit-column-gap: 5%;
                column-gap: 5%; 
        align-items: center;
        padding-bottom:1%;
    }
    .truck-info-text-description{
        width: 90%;
      }
      .truckinfo-date-field-size{
        width: 70%;
     }

     .truckinfo-phone-field-size{
        width:70%;
       }

    .vendor-code{
        width: 70%;
     }
     .vendor-text-field{
        width: 70%;
    }
    .driver-number-field{
        width: 70% 
       }
       .truck-width-normal{
        width: 70%;
        
    } 
    .overall-avail-status{
        padding: 2%;
        margin-top:13% 
    }
}



@media only screen and (max-width: 424px){

.driver-input-container {
    display: grid;
    grid-template-columns: 35% 43%;
    grid-column-gap: 5%;
    grid-column-gap: 14%;
    -webkit-column-gap: 14%;
            column-gap: 14%;
    align-items: center;
}

.truck-width-normal{
    width: 80%;
    
}

.truck-info-input{
    display: grid; 
    grid-template-columns: 23% 1fr; 
    grid-column-gap: 7%; 
    -webkit-column-gap: 7%; 
            column-gap: 7%; 
    align-items: center;
    padding-bottom:1%;
}
.truck-info-multi-input{
    display: grid; 
    grid-template-columns: 24% 32% 1fr; 
    grid-column-gap: 6%; 
    -webkit-column-gap: 6%; 
            column-gap: 6%; 
    align-items: center;
    padding-bottom:1%;
}
.truck-info-multi-input3{
    display: grid; 
    grid-template-columns: 25% 20% 7% 73%;
    grid-column-gap: 5%;
    -webkit-column-gap: 5%;
            column-gap: 5%; 
    align-items: center;
    padding-bottom:1%;
}
.truck-info-text-description{
    width: 90%;
  }
  .truck-info-safetytruck{
    width: 80%;
}
.text-field-size{
    width: 40%
}
.truckinfo-date-field-size{
    width: 80%;
 }
 .truckinfo-phone-field-size{
    width:80%;
   }
   .vendor-code{
    width: 80%;
 }
 .vendor-text-field{
     width: 80%;
 }
 .driver-number-field{
    width: 80% 
   }

}

@media only screen and (max-width: 320px){

    .driver-form-container{
        margin-top:3%;
        display:grid;
        grid-template-columns: 1fr;
        grid-row-gap: 8%;
        row-gap: 8%;
    }

    .driver-input-container {
        display: grid;
        grid-template-columns: 30% 50%;
        grid-column-gap: 5%;
        grid-column-gap: 14%;
        -webkit-column-gap: 14%;
                column-gap: 14%;
        align-items: center;
    }
    .overall-avail-status{
        padding: 2%;
        margin-top:18% 
    }

    .truck-info-input{
        display: grid; 
        grid-template-columns: 30% 1fr; 
        grid-column-gap: 8%; 
        -webkit-column-gap: 8%; 
                column-gap: 8%; 
        align-items: center;
        padding-bottom:1%;
    }
    .truck-info-multi-input{
        display: grid; 
        grid-template-columns: 32% 29% 1fr; 
        grid-column-gap: 6%; 
        -webkit-column-gap: 6%; 
                column-gap: 6%; 
        align-items: center;
        padding-bottom:1%;
    }
    .truck-info-multi-input3{
        display: grid; 
        grid-template-columns:33% 20% 7% 65%;
        grid-column-gap: 5%;
        -webkit-column-gap: 5%;
                column-gap: 5%; 
        align-items: center;
        padding-bottom:1%;
    }
}
/**
* Added By Akash
**/
.BottomRow {
    flex: 1 1 auto;
}

.Panel {
    display: flex;
    flex-direction: column;
    /* padding: 10px; */
}

.PanelContent {
    height: calc(100vh - 90px);
    width: 100%;
    /* background-color: aquamarine; */
    display: flex;
    flex-direction: row;
    overflow: hidden;
    /* padding: 10px; */
    /* align-items: center; */
    /* justify-content: center;
    overflow: hidden;
    border-radius: 0.5rem; */
}

.MuiButtonBase-root-tabstyle {
    font-size: 11px !important;
    padding: 10px 1px !important;
}

.MuiDataGrid-columnHeaders {
    background-color: rgba(241, 246, 252, 1);
    color: rgba(0, 0, 0, 1);
    font-size: 11px;
}

.super-app-theme--cell {
    background-color: white;
    font-size: 11px;
}

/*
* END
*/

.maincontainer_dispatch {
    background-color: white;
    display: flex;
    flex: 2 1;
    padding: 1.5rem;
}

.supcontainer {
    display: flex;
    flex: 1 1;
    padding: 10px;
    height: 100%;
}

.supcontainer-right {
    display: flex;
    flex: 1 1;
    padding: 10px;
    height: 100%;
    flex-direction: column;
}

.loadconteiners {
    display: flex;
    height: 50%;
    padding-bottom: 10px;

}

.dropconteiners {
    display: flex;
    height: 50%;
    padding-top: 10px;
}

.MuiTableCell-root-text {
    color: white !important;
    padding: 0px 15px !important;
    height: 40px !important;
}

.MuiButtonBase-root-tabstyle {
    font-size: 11px !important;
    padding: 10px 3px !important;
}

.subitemcolumnstyle {
    display: flex;
    flex-direction: row;
    height: 25px;
    font-size: 12px;
    font-weight: bold;

}

.MuiTableCell-root-subitemrowstyle {
    font-size: 13px !important;
}

.MuiTableCell-root-style {
    padding: 5px !important;
}

.rightsubtable {
    padding: 15px;
}

.MuiTypography-root {
    color: black;
}

.tableheader-style {
    height: 40px !important;
    padding: 0px !important;
}

.assignedTenderClass {
    background-color: blanchedalmond !important;
}

.tenderedClass {
    background-color: #fff !important;
}

.disableDriverRow {
    background-color: rgb(227 227 227) !important;
}

.defaultLighBlue {
    background-color: rgba(241, 246, 252, 1) !important
}

.highLightWithRed {
    color: red;
}

.highLightWithYellow{
    background: yellow;
}
/* Main Layout
.BottomRow {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* Controls */
.TopControls {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    flex-wrap: wrap;
}

/* Desktop Layout - Side by Side */
.PanelGroupContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: calc(100vh - 50px); /* Adjust based on header */
}

/* Panels */
.Panel {
    flex: 1 1;
    overflow: auto;
    min-height: 300px; /* Prevent height collapse */
    border: 1px solid #ccc;
    padding: 10px;
}

/* Responsive - Mobile View */
@media (max-width: 768px) {
    .PanelGroupContainer {
        flex-direction: column;
        height: auto;
    }

    .PanelGroup {
        flex-direction: column !important; /* Override horizontal layout */
    }

    .Panel {
        width: 100%;
        min-height: 250px;
    }
} 
.BottomRow {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.Panel {
    flex: 1 1;
    min-height: 150px; /* Prevents collapsing */
    max-height: 80vh; /* Prevents over-expanding */
    overflow: auto; /* Enables scrolling inside tables */
}

.PanelContent {
    width: 100%;
    height: 100%;
    overflow-y: auto; /* Ensures vertical scroll */
}

/* TruckDriverDetailEditPanel.css */
.form-container {
    margin: 10px;
  }
  
  .input-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }
  
  .input-field {
    flex: 1 1;
    margin-right: 10px;
  }
  
  .input-label {
    color: #000000;
    margin-bottom: 15px;
    font-size: 0.85rem;
  }
  
  .divider-chip {
    margin-top: 1%;
  }
  
  .hidden-label {
    visibility: hidden;
  }
  
/* .Container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.TopRow {
    flex: 0 0 auto;
    text-align: center;
}

.BottomRow {
    flex: 1 1 auto;
}

.Panel {
    display: flex;
    flex-direction: column;
}

.PanelContent {
    height: 100%;
    width: 100%;
    background-color: var(--color-panel-background);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 0.5rem;
} */

.styles_ResizeHandleOuter__eTNnW {
    flex: 0 0 0.02em;
    position: relative;
    outline: none;
    --background-color: transparent;
}

.styles_ResizeHandleOuter__eTNnW[data-resize-handle-active] {
    --background-color: var(--color-solid-resize-bar-handle);
}

.styles_ResizeHandleInner__1dxXV {
    position: absolute;
    top: 0.25em;
    bottom: 0.25em;
    left: 0.25em;
    right: 0.25em;
    border-radius: 0.25em;
    background-color: var(--background-color);
    transition: background-color 0.2s linear;
}

/* 
.Button {
    color: var(--color-default);
    background-color: var(--color-button-background);
    border: 1px solid var(--color-button-border);
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
}

.Button:hover {
    background-color: var(--color-button-background-hover);
}

.Link {
    display: block;
    color: var(--color-link);
    margin-bottom: 0.5rem;
} */

.styles_Icon__2Bf5v {
    width: 1em;
    height: 1em;
    position: absolute;
    left: calc(50% - 0.5rem);
    top: calc(50% - 0.5rem);
}
table{
    /* border-width: 1px;
    border-style: solid;
    border-color: #FF0000; */
}

tr {
    /* border-width: 1px;
    border-style: solid;
    border-color: #00FF00; */
}

td {
    padding:0.25em !important;
}

.inputField{
    padding:0.25em;
}
.StopsContainer{
    display: flex;
    flex-direction: column;
    text-align: right;
    padding: 1em;
    /* border:1px solid red; */
}


table {
    /* border-width: 1px;
    border-style: solid;
    border-color: #FF0000; */
}

tr {
    /* border-width: 1px;
    border-style: solid;
    border-color: #00FF00; */
}

td {
    padding: 0.25em !important;
}

.MuiInputBase-input {
    font-size: 14px !important;
    /* @media screen and (max-width: 992px) {
        font-size: 12px !important;
    } */
}

.inputField {
    padding: 2em;
}

.mainContainer {
    display: inline-block;
    text-align: left;
    padding: 1em;
    font-size: 1em !important;
}

.width_100 {
    width: 100%;
}

.width_50 {
    width: 50%;
}

.width_49 {
    width: 49%;
}
.width_99{
    padding-bottom:0.5rem;
    width:99%
}
.paddingleft{
    padding-left: 0.2rem;
}
.paddingright{
    padding-right: 0.2rem;
}
.paddingbottom{
    padding-bottom:0.5rem;
}

.width_33 {
    width: 33%;
    padding-left: 0.5rem ;
}

.dividersty {
    margin-top: 2%;
    margin-bottom: 1%;
    width: 100%;
}

.paperstyl {
    display: inline-block;
    padding: 1rem;
    margin-top: 1em;
    width: 100%;
}

.customernotes {
    display: flex;
    width: 100%;
    flex-direction: column;
}
.stoppaper2{
    width: 33.3%;
    flex-direction: row; 
    display: flex; 
    padding-bottom: 0.5rem;
}
.pickinguprow{
    flex-direction:row;
    display:flex;
}
.drivernotes{
    display: flex;
    width: 100%; 
    flex-direction: column;
}
.stopcontainerdiv{
    display: flex;
    flex-direction: row;
    justify-content: space-around; 
}
.stoppaper3{
    flex: 1 1; 
    margin: 5; 
    display: flex; 
    justify-content: center; 
    padding-top: 10;
    padding: 0.5rem
}
.commodity_truck{
    align-items:end;
    display:flex;
}
.autocomplestyl .MuiInputBase-root{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

/* .datepicker .MuiInputBase-input{
    padding: 9px 14px !important;
    
}
.datepicker .MuiInputBase-root .MuiOutlinedInput-notchedOutline{
    border-color: rgb(189, 243, 247) !important;
}
.datepicker .MuiFormLabel-root {
    color: rgb(168, 160, 160) !important;
}
.datepicker .MuiSvgIcon-root{
    width: 17px;
    height: 17px;

} */
.tablerow {
    padding: 0px !important;
}

.table2row {

    padding: 0px !important;
}

.borderright {
    border-right: 0.5px solid #707070 !important;
}

.MuiTableRow-root {
    .headerstyl {
        background-color: #DDE4FE;
    }

}

.tabletitleheader {
    text-align: left;
    align-items: center;
    justify-content: left;
    display: flex;
    height: 50px;
    background-color: #0B4387;
    padding-left: 10px;
}

.addnewrowdiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0.5rem;
}

.addrowicon {
    width: 16px;
    height: 16px;
}

.addbutton {
    -webkit-text-decoration-line: underline !important;
            text-decoration-line: underline !important;
    text-transform: capitalize !important;
    display: flex;
    color: white !important
}

.tabletitle {
    text-align: left;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 50px;
    border-radius: 10px 0px 0px 0px;
    background-color: #0B4387;
    width: 18%;
    margin-left: 0px;

    @media only screen and (max-width:767px) {
        width: 30%;
    }


}

.modifyiconcontainer {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
}

.tabledropicon {
    width: 17px;
    height: 17px;
    /* visibility: hidden; */
}

.tablerowtick {
    width: 20px;
    height: 20px;
}

.tablecross {
    width: 15px;
    height: 17px;
}

.tooltipstyl {
    background-color: white;
}

/* .tx_r_n {
    border-left: 1px solid red;
}
.tx_p_n {
    border-right: 1px solid red;
} */

.tx_r_y {
    border-left: 1px solid red;
}

.tx_p_y {
    border-right: 1px solid red;
}

.disabledCellOnSplit {
    background-color: #c9c6c6;
    opacity: 0.4;
}
table {
    /* border-width: 1px;
    border-style: solid;
    border-color: #FF0000; */
}

tr {
    /* border-width: 1px;
    border-style: solid;
    border-color: #00FF00; */
}

td {
    padding: 0.25em !important;
}

.MuiInputBase-input {
    font-size: 14px !important;
    /* @media screen and (max-width: 992px) {
        font-size: 12px !important;
    } */
}

.inputField {
    padding: 2em;
}

.mainContainer {
    display: inline-block;
    text-align: left;
    padding: 1em;
    font-size: 1em !important;
}

.width_100 {
    width: 100%;
}

.width_50 {
    width: 50%;
}

.width_49 {
    width: 49%;
}
.width_99{
    padding-bottom:0.5rem;
    width:99%
}
.paddingleft{
    padding-left: 0.2rem;
}
.paddingright{
    padding-right: 0.2rem;
}
.paddingbottom{
    padding-bottom:0.5rem;
}

.width_33 {
    width: 33%;
    padding-left: 0.5rem ;
}

.dividersty {
    margin-top: 2%;
    margin-bottom: 1%;
    width: 100%;
}

.paperstyl {
    display: inline-block;
    padding: 1rem;
    margin-top: 1em;
    width: 100%;
}

.paperstyl1 {
    padding: 1rem;
    margin-top: 1em;
    width: 100%;
}

.customernotes {
    display: flex;
    width: 100%;
    flex-direction: column;
}
.stoppaper2{
    width: 33.3%;
    flex-direction: row; 
    display: flex; 
    padding-bottom: 0.5rem;
}
.pickinguprow{
    flex-direction:row;
    display:flex;
}
.drivernotes{
    display: flex;
    width: 100%; 
    flex-direction: column;
}
.stopcontainerdiv{
    display: flex;
    flex-direction: row;
    justify-content: space-around; 
}
.stoppaper3{
    flex: 1 1; 
    margin: 5; 
    display: flex; 
    justify-content: center; 
    padding-top: 10;
    padding: 0.5rem
}
.commodity_truck{
    align-items:end;
    display:flex;
}
.autocomplestyl .MuiInputBase-root{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

/* .datepicker .MuiInputBase-input{
    padding: 9px 14px !important;
    
}
.datepicker .MuiInputBase-root .MuiOutlinedInput-notchedOutline{
    border-color: rgb(189, 243, 247) !important;
}
.datepicker .MuiFormLabel-root {
    color: rgb(168, 160, 160) !important;
}
.datepicker .MuiSvgIcon-root{
    width: 17px;
    height: 17px;

} */
table {
    /* border-width: 1px;
    border-style: solid;
    border-color: #FF0000; */
}

tr {
    /* border-width: 1px;
    border-style: solid;
    border-color: #00FF00; */
}

td {
    padding: 0.25em !important;
}

.MuiInputBase-input {
    font-size: 14px !important;
    /* @media screen and (max-width: 992px) {
        font-size: 12px !important;
    } */
}

.inputField {
    padding: 2em;
}

.mainContainer {
    display: inline-block;
    text-align: left;
    padding: 1em;
    font-size: 1em !important;
}

.width_100 {
    width: 100%;
}

.width_50 {
    width: 50%;
}

.width_49 {
    width: 49%;
}
.width_99{
    padding-bottom:0.5rem;
    width:99%
}
.paddingleft{
    padding-left: 0.2rem;
}
.paddingright{
    padding-right: 0.2rem;
}
.paddingbottom{
    padding-bottom:0.5rem;
}

.width_33 {
    width: 33%;
    padding-left: 0.5rem ;
}

.dividersty {
    margin-top: 2%;
    margin-bottom: 1%;
    width: 100%;
}

.paperstyl {
    display: inline-block;
    padding: 1rem;
    margin-top: 1em;
    width: 100%;
}

.customernotes {
    display: flex;
    width: 100%;
    flex-direction: column;
}
.stoppaper2{
    width: 33.3%;
    flex-direction: row; 
    display: flex; 
    padding-bottom: 0.5rem;
}
.pickinguprow{
    flex-direction:row;
    display:flex;
}
.drivernotes{
    display: flex;
    width: 100%; 
    flex-direction: column;
}
.stopcontainerdiv{
    display: flex;
    flex-direction: row;
    justify-content: space-around; 
}
.stoppaper3{
    flex: 1 1; 
    margin: 5; 
    display: flex; 
    justify-content: center; 
    padding-top: 10;
    padding: 0.5rem
}
.commodity_truck{
    align-items:end;
    display:flex;
}
.autocomplestyl .MuiInputBase-root{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

/* .datepicker .MuiInputBase-input{
    padding: 9px 14px !important;
    
}
.datepicker .MuiInputBase-root .MuiOutlinedInput-notchedOutline{
    border-color: rgb(189, 243, 247) !important;
}
.datepicker .MuiFormLabel-root {
    color: rgb(168, 160, 160) !important;
}
.datepicker .MuiSvgIcon-root{
    width: 17px;
    height: 17px;

} */
table {
    /* border-width: 1px;
    border-style: solid;
    border-color: #FF0000; */
}

tr {
    /* border-width: 1px;
    border-style: solid;
    border-color: #00FF00; */
}

td {
    padding: 0.25em !important;
}

.MuiInputBase-input {
    font-size: 14px !important;
    /* @media screen and (max-width: 992px) {
        font-size: 12px !important;
    } */
}

.inputField {
    padding: 2em;
}

.mainContainer {
    display: inline-block;
    text-align: left;
    padding: 1em;
    font-size: 1em !important;
}

.width_100 {
    width: 100%;
}

.width_50 {
    width: 50%;
}

.width_25 {
    width: 25%;
}

.width_49 {
    width: 49%;
}
.width_99{
    padding-bottom:0.5rem;
    width:99%
}
.paddingleft{
    padding-left: 0.2rem;
}
.paddingright{
    padding-right: 0.2rem;
}
.paddingbottom{
    padding-bottom:0.5rem;
}

.width_33 {
    width: 33%;
    padding-left: 0.5rem ;
}

.dividersty {
    margin-top: 2%;
    margin-bottom: 1%;
    width: 100%;
}

.paperstyl {
    display: inline-block;
    padding: 1rem;
    margin-top: 1em;
    width: 100%;
}

.customernotes {
    display: flex;
    width: 100%;
    flex-direction: column;
}
.stoppaper2{
    width: 33.3%;
    flex-direction: row; 
    display: flex; 
    padding-bottom: 0.5rem;
}
.pickinguprow{
    flex-direction:row;
    display:flex;
}
.drivernotes{
    display: flex;
    width: 100%; 
    flex-direction: column;
}
.stopcontainerdiv{
    display: flex;
    flex-direction: row;
    justify-content: space-around; 
}
.stoppaper3{
    flex: 1 1; 
    margin: 5; 
    display: flex; 
    justify-content: center; 
    padding-top: 10;
    padding: 0.5rem
}
.commodity_truck{
    align-items:end;
    display:flex;
}
.autocomplestyl .MuiInputBase-root{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

/* .datepicker .MuiInputBase-input{
    padding: 9px 14px !important;
    
}
.datepicker .MuiInputBase-root .MuiOutlinedInput-notchedOutline{
    border-color: rgb(189, 243, 247) !important;
}
.datepicker .MuiFormLabel-root {
    color: rgb(168, 160, 160) !important;
}
.datepicker .MuiSvgIcon-root{
    width: 17px;
    height: 17px;

} */
.autocomplestyl .MuiInputBase-root{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
/* .css-yrdy0g-MuiDataGrid-columnHeaderRow{
    background-color: #DDE4FE;
} */

.paperstyl{
    display: flex !important;
    flex-direction: column; 
    flex: 1 1; 
    padding: 1rem !important;
    align-items: right !important; 
    max-height: 60% !important;
}
.gridby_container{
    width: 100%; 
    background-color: #0B4387; 
    height: 55px; 
    border-top-left-radius: 10px; 
    border-top-right-radius: 10px; 
    align-items: center; 
    display: flex; 
    justify-content: end ;

}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
    outline: 0;
}

body {
    font-family: Roboto, Helvetica, Arial, sans-serif;
}

* {
    box-sizing: border-box;
    -webkit-user-select: auto;
        -ms-user-select: auto;
            user-select: auto;
}

.react-datepicker__header__dropdown--select {
    margin: 5px;
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.loader_container {
    display: flex;
    justify-content: center;
}


/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
    -webkit-appearance: none;
    -moz-appearance: none;
}

input[type=search] {
    -webkit-appearance: none;
    -moz-appearance: none;
    box-sizing: content-box;
}

textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
    display: none;
    height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
    display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
    font-size: 100%; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
    -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a:focus {
    outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
    outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
    border: 0; /* 1 */
    -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
    margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
    margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
    border: 0; /* 1 */
    padding: 0;
    white-space: normal; /* 2 */
    *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
    font-size: 100%; /* 1 */
    margin: 0; /* 2 */
    vertical-align: baseline; /* 3 */
    border: none;

}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
    line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
    text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button; /* 2 */
    cursor: pointer; /* 3 */
    *overflow: visible;  /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
    cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
    *height: 13px; /* 3 */
    *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type="search"] {
    -webkit-appearance: textfield; /* 1 */ /* 2 */
    box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
    overflow: auto; /* 1 */
    vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html,
button,
input,
select,
textarea {
    color: #222;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

img {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.chromeframe {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}
