/* TruckDriverDetailEditPanel.css */
.form-container {
    margin: 10px;
  }
  
  .input-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }
  
  .input-field {
    flex: 1;
    margin-right: 10px;
  }
  
  .input-label {
    color: #000000;
    margin-bottom: 15px;
    font-size: 0.85rem;
  }
  
  .divider-chip {
    margin-top: 1%;
  }
  
  .hidden-label {
    visibility: hidden;
  }
  