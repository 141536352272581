.driver-form-container{
    margin-top:3%;
    display:grid;
    grid-template-columns:1fr 1fr 1fr 1fr
}

.driver-input-container{
    display: grid;
    grid-template-columns: 35% 40%;
    column-gap: 2%;
    align-items: center
}

.truck-info-input{
    display: grid; 
    grid-template-columns: 20% 1fr; 
    column-gap: 3%; 
    align-items: center;
    padding-bottom:1%;
}

.truck-info-multi-input{
    display: grid; 
    grid-template-columns: 20% 10% 1fr; 
    column-gap: 3%; 
    align-items: center;
    padding-bottom:1%;
}

.truck-info-multi-input3{
    display: grid; 
    grid-template-columns: 20% 15% 4% 1fr; 
    column-gap: 3%; 
    align-items: center;
    padding-bottom:1%;
}

.truck-driverinfo-list-wrapper{
    overflow-x: hidden;
    margin:2% 
}

.truck-driverinfo-empty-data-wrapper{
    margin:  0 auto;
    display: grid
}

.company-info-heading {
    text-align: center;
    background: blue;
    padding-top: 10px;
    padding-bottom: 10px;
}

.truck-driver-subheading {
font-weight: bold;
margin-bottom: 2%;
}
.text-field-size{
    width: 10%
}

.truckinfo-date-field-size{
   width: 20%;
}

.truckinfo-phone-field-size{
    width:35%;
   }

.driverinfo-text2{
   width:50%;     
}
.driver-number-field{
 width: 30% 
}

.overall-availability-text{
    padding: 2%;
}

.truck-driver-back-button{
  
   cursor: pointer;
    background-color: red;
    padding: 10px;
    border-radius: 12px;
    float: right;
}

.driver-name-text{
    width: 110%;
}
.driver-name-middlename{
    width: 140%;
}
.driver-name-lastname{
    width: 39%;
}
.truck-liscense-no{
   width: 125%;
}

.truck-info-text-description{
  width: 50%;
}
.truck-width-normal{
    width: 50%;
    
}

.truck-info-safetytruck{
     width: 40%;
}

.vendor-code{
   width: 20%;
}
.vendor-text-field{
    width: 50%;
}

.overall-avail-status{
    padding: 2%;
    margin-top:2% 
}


@media only screen and (max-width:1023px){

    .driver-form-container{
        margin-top:3%;
        display:grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 10%;
    }


    .driver-input-container{
        display: grid;
        grid-template-columns: 42% 40%;
        column-gap: 5%;
        align-items: center;
        
    }
    .truck-lable{
        justify-self: end;
    }
    .text-field-size{
        width: 13%
    }
    .truckinfo-date-field-size{
        width: 30%;
     }
     .truckinfo-phone-field-size{
        width:45%;
       }

       .truck-info-input{
        display: grid; 
        grid-template-columns: 23% 1fr; 
        column-gap: 3%; 
        align-items: center;
        padding-bottom:1%;
    }
    .truck-info-multi-input3{
        display: grid; 
        grid-template-columns: 17% 17% 9% 49%;
        column-gap: 9%; 
        align-items: center;
        padding-bottom:1%;
    }

    .truck-info-multi-input{
        display: grid; 
        grid-template-columns: 23% 16% 1fr; 
        column-gap: 3%; 
        align-items: center;
        padding-bottom:1%;
    }
    .driverinfo-text2{
        width:80%;
    }

    .driver-name-text{
        width: 110%;
    }
    .driver-name-middlename{
        width: 150%;
    }
    .driver-name-lastname{
        width: 39%;
    }
    .truck-liscense-no{
        width: 105%;
     }

}

@media only screen and (max-width:767px){
    
    .driver-form-container{
        margin-top:3%;
        display:grid;
        grid-template-columns: 1fr;
        row-gap: 8%;
    }

    .driver-input-container{
        display: grid;
        grid-template-columns: 35% 35%;
        column-gap: 5%;
        align-items: center
    }

    .overall-availability-text{
        padding: 5%;
    }
    .text-field-size{
        width: 25%
    }
    .truckinfo-date-field-size{
        width: 50%;
     }
     .truck-driverinfo-list-wrapper{
        overflow-x: hidden;
        padding: -20px;
    }
    .truck-driver-back-button{
        margin-right: 10%;
    }
    .truck-info-multi-input{
        display: grid; 
        grid-template-columns: 20% 28% 1fr; 
        column-gap: 6%; 
        align-items: center;
        padding-bottom:1%;
    }

    .truck-info-multi-input3{
        display: grid; 
        grid-template-columns: 21% 20% 7% 73%;
        column-gap: 5%; 
        align-items: center;
        padding-bottom:1%;
    }
    .truck-info-text-description{
        width: 90%;
      }
      .truckinfo-date-field-size{
        width: 70%;
     }

     .truckinfo-phone-field-size{
        width:70%;
       }

    .vendor-code{
        width: 70%;
     }
     .vendor-text-field{
        width: 70%;
    }
    .driver-number-field{
        width: 70% 
       }
       .truck-width-normal{
        width: 70%;
        
    } 
    .overall-avail-status{
        padding: 2%;
        margin-top:13% 
    }
}



@media only screen and (max-width: 424px){

.driver-input-container {
    display: grid;
    grid-template-columns: 35% 43%;
    grid-column-gap: 5%;
    column-gap: 14%;
    align-items: center;
}

.truck-width-normal{
    width: 80%;
    
}

.truck-info-input{
    display: grid; 
    grid-template-columns: 23% 1fr; 
    column-gap: 7%; 
    align-items: center;
    padding-bottom:1%;
}
.truck-info-multi-input{
    display: grid; 
    grid-template-columns: 24% 32% 1fr; 
    column-gap: 6%; 
    align-items: center;
    padding-bottom:1%;
}
.truck-info-multi-input3{
    display: grid; 
    grid-template-columns: 25% 20% 7% 73%;
    column-gap: 5%; 
    align-items: center;
    padding-bottom:1%;
}
.truck-info-text-description{
    width: 90%;
  }
  .truck-info-safetytruck{
    width: 80%;
}
.text-field-size{
    width: 40%
}
.truckinfo-date-field-size{
    width: 80%;
 }
 .truckinfo-phone-field-size{
    width:80%;
   }
   .vendor-code{
    width: 80%;
 }
 .vendor-text-field{
     width: 80%;
 }
 .driver-number-field{
    width: 80% 
   }

}

@media only screen and (max-width: 320px){

    .driver-form-container{
        margin-top:3%;
        display:grid;
        grid-template-columns: 1fr;
        row-gap: 8%;
    }

    .driver-input-container {
        display: grid;
        grid-template-columns: 30% 50%;
        grid-column-gap: 5%;
        column-gap: 14%;
        align-items: center;
    }
    .overall-avail-status{
        padding: 2%;
        margin-top:18% 
    }

    .truck-info-input{
        display: grid; 
        grid-template-columns: 30% 1fr; 
        column-gap: 8%; 
        align-items: center;
        padding-bottom:1%;
    }
    .truck-info-multi-input{
        display: grid; 
        grid-template-columns: 32% 29% 1fr; 
        column-gap: 6%; 
        align-items: center;
        padding-bottom:1%;
    }
    .truck-info-multi-input3{
        display: grid; 
        grid-template-columns:33% 20% 7% 65%;
        column-gap: 5%; 
        align-items: center;
        padding-bottom:1%;
    }
}